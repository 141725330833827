import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Modal from 'antd/lib/modal/Modal'
import { Button, Select } from 'antd'
import UpdateByExcel from './UpdateByExcel'
import { EnumExcelType } from '../../types/enums'
import Download from '../icon/Download'
import { IUploadTemplateOptions } from './Upload'

const UpdateByExcelContainer = styled(UpdateByExcel)`
  margin-top: 2.4rem;
`

const SelectionContainer = styled.div`
  display: flex;
  align-items: center;
`

interface UploadContentProps {
  visible: boolean
  templateOptions: IUploadTemplateOptions[]
  handleCancel: () => void
  uploadCallback: () => void
}

const UploadContent = ({
  visible,
  templateOptions,
  handleCancel,
  uploadCallback,
}: UploadContentProps) => {
  const downloadButtonRef = useRef(null)
  const [templateIndex, setTemplateIndex] = useState<number>(0)

  const onClickDownloadButton = () => {
    downloadButtonRef.current?.downloadFile &&
      downloadButtonRef.current?.downloadFile()
  }

  return (
    <>
      <Modal
        visible={visible}
        title="待编辑文件上传"
        footer={null}
        onCancel={handleCancel}
      >
        <SelectionContainer>
          <label>选择模板:</label>
          <Select
            defaultValue={templateIndex}
            style={{ width: 120, margin: '0 8px' }}
            onChange={(index) => {
              setTemplateIndex(index)
            }}
          >
            {templateOptions.map((item, index) => (
              <Select.Option value={index} key={index}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
          <Button onClick={onClickDownloadButton}>
            <Download
              ref={downloadButtonRef}
              saveName={templateOptions[templateIndex].label}
              fileUrl={templateOptions[templateIndex].templateUrl}
            />
            下载模板
          </Button>
        </SelectionContainer>
        <UpdateByExcelContainer
          uploadCallback={uploadCallback}
          url={templateOptions[templateIndex].uploadUrl}
        />
      </Modal>
    </>
  )
}

export default UploadContent
