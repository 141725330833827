import React, { useEffect, useState } from 'react'
import IconContent from '../icon/IconContent'
import { UploadOutlined } from '@ant-design/icons'
import UploadContent from './UploadContent'
import { EnumExcelType } from '@enums'

export interface IUploadTemplateOptions {
  label: string
  fileName: EnumExcelType
  templateUrl: string
  uploadUrl: string
}

interface IDownloadIconProp {
  templateOptions: IUploadTemplateOptions[]
  uploadCallback?: () => void
}

const UploadIcon = ({ templateOptions, uploadCallback }: IDownloadIconProp) => {
  const [isShowUploadModal, showUploadModal] = useState<boolean>(false)

  const toggleUploadModal = () => {
    showUploadModal((current) => !current)
  }

  const uploadComplate = async () => {
    uploadCallback()
  }

  return (
    <>
      <IconContent
        icon={UploadOutlined}
        clickCallback={toggleUploadModal}
        tips={'上传'}
      />
      <UploadContent
        visible={isShowUploadModal}
        templateOptions={templateOptions}
        handleCancel={toggleUploadModal}
        uploadCallback={uploadComplate}
      />
    </>
  )
}

export default UploadIcon
