import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { InboxOutlined } from '@ant-design/icons'
import apiCollection from '../../api'
import { message, notification, Upload } from 'antd'
import FullScreenLoading from '../icon/FullScreenLoading'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import XLSX from 'xlsx'
import { useStoreViewContext } from '../../context/storeViewContext/StoreViewContext'
import { EnumConfigType, EnumFormFieldType } from '../../types/enums'
import {
  BCFiedls,
  ITSupportFields,
  ContractFields,
  HRFields,
  StoreInfoFields,
} from '../ownStore/FormField'
import { debounce } from 'lodash'

const NotificationDescriptionBox = styled.div`
  white-space: pre-wrap;
`

const InboxOutlinedBox = styled.div`
  color: #40a9ff;
  font-size: 4.8rem;
  font-style: normal;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
`

const UploadTextContent = styled.div`
  margin: 0 0 0.4rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.6rem;
`

interface IUploadIconProp {
  url: string
  uploadCallback: () => void
}

const UploadIcon = ({ uploadCallback, url, ...props }: IUploadIconProp) => {
  const [isLoading, showLoading] = useState<boolean>(false)

  const toggleLoading = debounce((isShowLoaing: boolean) => {
    showLoading(isShowLoaing)
  }, 600)

  const uploadDataByExcel = async (data: any[]) => {
    const result = await apiCollection.uploadDataByExcel({ url, data })
    if (!result.success) {
      message.error(result.message)
    } else {
      uploadCallback()
      notification.open({
        message: '上传成功',
        description: '自动更新数据。',
      })
    }
  }

  const formatDataByExcel = async ({ file }: UploadRequestOption) => {
    const fileReader = new FileReader()
    fileReader.onload = async (event) => {
      toggleLoading(true)
      try {
        const { result } = event.target
        const workbook = XLSX.read(result, { type: 'binary', cellDates: true })
        let data = []
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
          }
        }

        await uploadDataByExcel(data)
      } catch (e) {
        message.error('文件类型不正确')
      }
      toggleLoading(false)
    }
    fileReader.readAsBinaryString(file as Blob)
  }

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    if (!isJpgOrPng) {
      message.error('请选择.xlsx文件格式进行上传')
    }
    return isJpgOrPng
  }

  return (
    <>
      <Upload.Dragger
        beforeUpload={beforeUpload}
        maxCount={1}
        itemRender={() => <></>}
        customRequest={formatDataByExcel}
        {...props}
      >
        <InboxOutlinedBox>
          <InboxOutlined />
        </InboxOutlinedBox>
        <UploadTextContent>单击或拖动文件到此区域进行上传</UploadTextContent>
      </Upload.Dragger>
      {isLoading && <FullScreenLoading />}
    </>
  )
}

export default UploadIcon
